img { 
  float: right; 
  padding: 15px; 
  object-fit:cover;
  width: 33%;
  height: 33%;
}

.logo { 
	display: inline-block;
	position: relative;
	width: 100%;
	vertical-align: middle; 
	overflow: hidden; 
}


.nav { 
  font-size: 10min;
  padding: 16px; 
} 

.page{
  background-color: var(--bg-color);
  color: var(--text-color)
}

.dark {
  --bg-color: #181a1b;
  --text-color: #e8e6e3;

  a:link { color: #66b9b4;; }
  a:visited { color: gray; }
  a:hover { color: hotpink; }

  .svg_white {fill: #181a1b; }
  .svg_softteal {fill: rgb(54, 52, 52); }
  .svg_yellow { fill: #3d6a67; }
}

.light {
  --bg-color: white;
  --text-color: black;
  a:link { color: #66b9b4;; }
  a:visited { color: gray; }
  a:hover { color: hotpink; }
}

.content:hover .toggle-icon {
  animation: wiggle 1s linear;
}

.toggle { 
	display: inline-block;
	position: relative;
	width: 100%;
	vertical-align: middle; 
	overflow: hidden; 
}

.toggle-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  user-select: none;
}

.toggle-label:hover {
  background-color: var(--shadow);
}

.toggle-label:active {
  transform: scale(0.95);
}

* { box-sizing: border-box; }
/* Create two unequal columns that floats next to each other */
.column {
  float: left;
  padding: 10px;
}
.left { width: 25%; }
.right { width: 75%; }

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive Resize based on screen */
@media screen and (max-width: 600px) { 
  .column { 
    width: 100%;
    font-size: 4vmin;
  } 
}

/* Syntax Highlighting */
.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight .cm {
  color: #999988;
  font-style: italic;
}
.highlight .cp {
  color: #999999;
  font-weight: bold;
}
.highlight .c1 {
  color: #999988;
  font-style: italic;
}
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic;
}
.highlight .c, .highlight .ch, .highlight .cd, .highlight .cpf {
  color: #999988;
  font-style: italic;
}
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}
.highlight .gd {
  color: #000000;
  background-color: #ffdddd;
}
.highlight .ge {
  color: #000000;
  font-style: italic;
}
.highlight .gr {
  color: #aa0000;
}
.highlight .gh {
  color: #999999;
}
.highlight .gi {
  color: #000000;
  background-color: #ddffdd;
}
.highlight .go {
  color: #888888;
}
.highlight .gp {
  color: #555555;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gu {
  color: #aaaaaa;
}
.highlight .gt {
  color: #aa0000;
}
.highlight .kc {
  color: #000000;
  font-weight: bold;
}
.highlight .kd {
  color: #000000;
  font-weight: bold;
}
.highlight .kn {
  color: #000000;
  font-weight: bold;
}
.highlight .kp {
  color: #000000;
  font-weight: bold;
}
.highlight .kr {
  color: #000000;
  font-weight: bold;
}
.highlight .kt {
  color: #445588;
  font-weight: bold;
}
.highlight .k, .highlight .kv {
  color: #000000;
  font-weight: bold;
}
.highlight .mf {
  color: #009999;
}
.highlight .mh {
  color: #009999;
}
.highlight .il {
  color: #009999;
}
.highlight .mi {
  color: #009999;
}
.highlight .mo {
  color: #009999;
}
.highlight .m, .highlight .mb, .highlight .mx {
  color: #009999;
}
.highlight .sa {
  color: #000000;
  font-weight: bold;
}
.highlight .sb {
  color: #d14;
}
.highlight .sc {
  color: #d14;
}
.highlight .sd {
  color: #d14;
}
.highlight .s2 {
  color: #d14;
}
.highlight .se {
  color: #d14;
}
.highlight .sh {
  color: #d14;
}
.highlight .si {
  color: #d14;
}
.highlight .sx {
  color: #d14;
}
.highlight .sr {
  color: #009926;
}
.highlight .s1 {
  color: #d14;
}
.highlight .ss {
  color: #990073;
}
.highlight .s, .highlight .dl {
  color: #d14;
}
.highlight .na {
  color: #008080;
}
.highlight .bp {
  color: #999999;
}
.highlight .nb {
  color: #0086B3;
}
.highlight .nc {
  color: #445588;
  font-weight: bold;
}
.highlight .no {
  color: #008080;
}
.highlight .nd {
  color: #3c5d5d;
  font-weight: bold;
}
.highlight .ni {
  color: #800080;
}
.highlight .ne {
  color: #990000;
  font-weight: bold;
}
.highlight .nf, .highlight .fm {
  color: #990000;
  font-weight: bold;
}
.highlight .nl {
  color: #990000;
  font-weight: bold;
}
.highlight .nn {
  color: #555555;
}
.highlight .nt {
  color: #000080;
}
.highlight .vc {
  color: #008080;
}
.highlight .vg {
  color: #008080;
}
.highlight .vi {
  color: #008080;
}
.highlight .nv, .highlight .vm {
  color: #008080;
}
.highlight .ow {
  color: #000000;
  font-weight: bold;
}
.highlight .o {
  color: #000000;
  font-weight: bold;
}
.highlight .w {
  color: #bbbbbb;
}
.highlight {
  background-color: #f8f8f8;
}
